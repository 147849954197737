<template>
  <div class="home">
    <h1 class="d-sr-only">{{ title }}</h1>
    <p class="d-sr-only" v-text="description" />

    <!-- CAROUSEL -->
    <div class="primary proposals">
      <v-container v-if="carousel.length > 0">
        <v-row>
          <v-col cols="12" md="8" xl="9" class="mb-sm-8 mb-md-0">
            <ProposalSlider :proposals="carousel" position="home_carousel" />
          </v-col>
          <!-- SIDEBOX BANNER (web) -->
          <v-col cols="12" md="4" xl="3" class="sidebox-banner">
            <ProposalBrandGrid
              :proposals="isAuthenticated ? sideboxBannerLogged : sideboxBanner"
              position="home_sidebox_banner"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- HEADER BANNER -->
    <v-container class="secondary lighten-2 px-0 px-sm-3 mt-10">
      <ProposalGrosMarketGrid
        :title="$t('headerBanner.title')"
        :proposals="headerBanner"
      />
    </v-container>

    <!-- FOOTER BANNER -->
    <v-container>
      <ProposalSlider
        :title="$t('footerBanner.title')"
        :slidesPerView="2"
        :paginationClass="'swiper-pagination-footer'"
        :proposals="footerBanner"
      />
    </v-container>

    <!-- HEADER PRODUCTS -->
    <div
      class="secondary product-slider-wrapper"
      v-for="proposal in headerProducts"
      :key="proposal.id"
    >
      <ProductListSlider
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
        :key="key"
        class="secondary"
        position="home_header_products"
      />
    </div>

    <!-- OFFERTA -->
    <v-container class="offer-slider-wrapper">
      <OffertaSlider :title="$t('offerSlider.title')" />
    </v-container>

    <!-- INDUSTRY BANNER -->
    <v-container>
      <ProposalSlider
        :title="$t('industryBanner.title')"
        :slidesPerView="2"
        :paginationClass="'swiper-pagination-industry'"
        :proposals="industryBanner"
      />
    </v-container>

    <!-- FOOTER PRODUCTS -->
    <div
      class="footer-slider-wrapper"
      v-for="proposal in footerProducts"
      :key="proposal.id"
    >
      <ProductListSlider
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
        :key="key"
        position="home_footer_products"
      />
    </div>

    <!-- FOOTER CARDS -->
    <v-container>
      <FooterCardsGrid :cardsName="['areaDidattica', 'storeLocator']" />
    </v-container>

    <be-customer-card-horizontal card="registrationHome" />
    <newsletter-horizontal />
  </div>
</template>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import OffertaSlider from "@/components/proposal/OffertaSlider.vue";
import ProposalGrosMarketGrid from "@/components/proposal/ProposalGrosMarketGrid.vue";
import ProposalBrandGrid from "@/components/proposal/ProposalBrandGrid.vue";
import BeCustomerCardHorizontal from "@/components/cards/BeCustomerCardHorizontal.vue";
import FooterCardsGrid from "@/components/cards/FooterCardsGrid.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";
import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapProposal } from "~/service/ebsn.js";
import { mapActions, mapGetters } from "vuex";

import get from "lodash/get";
import split from "lodash/split";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    ProductListSlider,
    ProposalSlider,
    ProposalGrosMarketGrid,
    ProposalBrandGrid,
    OffertaSlider,
    BeCustomerCardHorizontal,
    NewsletterHorizontal,
    FooterCardsGrid
  },
  data() {
    return {
      slides: {},
      key: 1
    };
  },
  methods: {
    ...mapActions({
      resetFilters: "category/resetFilters"
    }),
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapProposal({
      carousel: "carousel",
      sideboxBanner: "sidebox-banner",
      sideboxBannerLogged: "sidebox-banner-logged",
      headerProducts: "header-products",
      headerBanner: "header-banner",
      footerBanner: "footer-banner",
      footerProducts: "footer-products",
      industryBanner: "industry-banner"
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    }
  }
};
</script>
