<template>
  <v-card
    rounded="md"
    :href="link"
    @click.prevent="handleLink"
    elevation="0"
    height="170"
    class="card-wrapper"
  >
    <v-img :src="proposal.img" height="100%" class="card-image">
      <div
        class="pa-2 align-center card-content"
        :style="styleObject"
        v-html="proposal.content"
        @click="clicked"
      ></div>
      <v-spacer />
      <div class="d-flex justify-center">
        <v-btn
          depressed
          color="primary"
          v-if="proposal.metaData.category_proposal_type.BTN_LABEL"
          :href="proposal.url"
        >
          {{ proposal.metaData.category_proposal_type.BTN_LABEL }}
        </v-btn>
      </div>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.card-image {
  padding: 20px;
  text-align: center;
  display: flex;
  .v-responsive__content {
    display: flex;
    flex-direction: column;
  }
  .card-content {
    font-weight: 600;
    font-size: 22px;
    color: $primary;
    p {
      text-align: center;
      margin-bottom: 0;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";

export default {
  name: "ProposalBrandCard",
  props: {
    proposal: { type: Object, required: false }
  },
  computed: {
    styleObject() {
      let color = get(this.proposal, "metaData.category_proposal_type.COLOR");
      if (color) {
        return { color: color };
      }
      return null;
    },
    btnClass() {
      return get(this.proposal, "metaData.category_proposal_type.BTN_CLASS");
    }
  },
  mixins: [banner, clickHandler]
};
</script>
