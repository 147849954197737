<template>
  <div class="pt-3 pt-sm-0 py-md-5" v-intersect.once="handleView">
    <h2 v-html="title" v-if="title" @click="clicked"></h2>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="12"
        v-for="proposal in proposals"
        :key="proposal.proposalId"
      >
        <proposal-brand-card :proposal="proposal" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProposalBrandCard from "./ProposalBrandCard.vue";
import proposalMixin from "~/mixins/proposal";
import clickHandler from "~/mixins/clickHandler";

export default {
  components: { ProposalBrandCard },
  name: "ProposalBrandGrid",
  mixins: [proposalMixin, clickHandler]
};
</script>
